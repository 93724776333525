import { CustomerShort } from "./customers";
import { OrderType, SidebarPrefix, SyncStatus } from "./general";
import { Order, OrderPaymentStatus } from "./orders";
import { StoreShort } from "./stores";

export interface Bill {
  id: number;
  supplier: CustomerShort;
  type: OrderType;
  store: StoreShort;
  status: BillStatus;
  payment_status: OrderPaymentStatus;
  created_at: string;
  invoice_number: number;
  invoice_due_date: string;
  paid_value: number;
  total_value: number;
  balance: number;
  sync_status: SyncStatus;
  reference: string;
}

export interface BillSingle extends Bill {
  order: Order;
  xero_invoice: null | number
}

export enum BillStatus {
  ACTIVE = "active",
  AWAITING_PAYMENT = "awaiting_payment",
  PAID = "paid",
  OVERDUE = "overdue",
  DRAFT = "draft",
}

export enum BillDateTypes {
  CREATION = "creation",
  TARGET = "target",
}
export const SidebarPage = {
  BILLS_FILTERS: `${SidebarPrefix.BILLS}-filters`,
  BILLS_SEND: `${SidebarPrefix.BILLS}-send`,
  BILLS_BATCH_SYNC: `${SidebarPrefix.BILLS}-batch-sync`,
  BILLS_HIDE_QUICK_FILTERS: `${SidebarPrefix.BILLS}-hide-quick-filters`,
  BILLS_AUTOMATED_STATEMENT_SETTINGS: `${SidebarPrefix.BILLS}-automated-statement-settings`,
};
