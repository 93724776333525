import { defineStore } from "pinia";
import { Customer, CustomerSingle } from "~/types/customers";
import {
  Meta,
  ResponseType,
  Option,
  SortType,
  SyncStatus,
} from "~/types/general";
import { Filters, FilterType } from "~/types/filters";
import { KanbanLane } from "~/types/orders";
import {
  PurchaseCalculatePayload,
  PurchaseOrderManage,
  PurchaseOrder,
  PurchaseOrderStatus,
  PurchaseOrderType,
  PurchasePricingMethod,
  FulfilmentStatus,
} from "~/types/purchase";
import { useNotesStore } from "./notes";
import { useTasksStore } from "~/store/tasks";
import { PaymentMethodGateway } from "~/types/payment";
import { BillDateTypes } from "~/types/bills";
import { NotificationTag } from "~/types/notifications";

export const usePurchaseStore = defineStore("purchase", () => {
  // Purchase Orders
  const orders = ref<PurchaseOrder[]>([]);
  const meta = ref<Meta>({} as Meta);
  const viewingOrder = ref<PurchaseOrder>({} as PurchaseOrder);
  const cachedOrder = ref<PurchaseOrder>({} as PurchaseOrder);
  const editMode = ref<boolean>(false);

  // Status Tags and Options
  const statusTags = ref<Option[]>([
    { name: "Draft", value: PurchaseOrderStatus.DRAFT },
    { name: "Pending", value: PurchaseOrderStatus.PENDING },
    { name: "On Hold", value: PurchaseOrderStatus.ON_HOLD },
    { name: "Processing", value: PurchaseOrderStatus.PROCESSING },
    { name: "Pending Approval", value: PurchaseOrderStatus.PENDING_APPROVAL },
    { name: "Receiving", value: PurchaseOrderStatus.RECEIVING },
    {
      name: "Partially Completed",
      value: PurchaseOrderStatus.PARTIALLY_COMPLETED,
    },
    { name: "Confirmed", value: PurchaseOrderStatus.CONFIRMED },
    { name: "Selling", value: PurchaseOrderStatus.SELLING },
    { name: "Delayed", value: PurchaseOrderStatus.DELAYED },
    { name: "Backordered", value: PurchaseOrderStatus.BACKORDERED },
    { name: "Completed", value: PurchaseOrderStatus.COMPLETED },
    { name: "Refunded", value: PurchaseOrderStatus.REFUNDED },
    { name: "Cancelled", value: PurchaseOrderStatus.CANCELLED },
    { name: "Returned", value: PurchaseOrderStatus.RETURNED },
    { name: "Archived", value: PurchaseOrderStatus.ARCHIVED },
  ]);
  const consignmentOrderStatuses = ref<PurchaseOrderStatus[]>([
    PurchaseOrderStatus.DRAFT,
    PurchaseOrderStatus.PENDING,
    PurchaseOrderStatus.ON_HOLD,
    PurchaseOrderStatus.PROCESSING,
    PurchaseOrderStatus.RECEIVING,
    PurchaseOrderStatus.PARTIALLY_COMPLETED,
    PurchaseOrderStatus.SELLING,
    PurchaseOrderStatus.DELAYED,
    PurchaseOrderStatus.RETURNED,
    PurchaseOrderStatus.REFUNDED,
    PurchaseOrderStatus.CANCELLED,
    PurchaseOrderStatus.ARCHIVED,
    PurchaseOrderStatus.COMPLETED,
  ]);
  const pricedOrderStatuses = ref<PurchaseOrderStatus[]>([
    PurchaseOrderStatus.DRAFT,
    PurchaseOrderStatus.PENDING,
    PurchaseOrderStatus.PENDING_APPROVAL,
    PurchaseOrderStatus.ON_HOLD,
    PurchaseOrderStatus.PROCESSING,
    PurchaseOrderStatus.RECEIVING,
    PurchaseOrderStatus.CONFIRMED,
    PurchaseOrderStatus.BACKORDERED,
    PurchaseOrderStatus.DELAYED,
    PurchaseOrderStatus.REFUNDED,
    PurchaseOrderStatus.RETURNED,
    PurchaseOrderStatus.CANCELLED,
    PurchaseOrderStatus.ARCHIVED,
    PurchaseOrderStatus.PARTIALLY_COMPLETED,
    PurchaseOrderStatus.COMPLETED,
  ]);
  const orderTypeOptions = ref<Option[]>([
    { name: "Delivery", value: PurchaseOrderType.DELIVERY },
    { name: "Pick Up", value: PurchaseOrderType.PICKUP },
  ]);
  const purchasingMethods = ref<Option[]>([
    { name: "Consignment", value: PurchasePricingMethod.CONSIGNMENT },
    { name: "Purchase Order", value: PurchasePricingMethod.PRICED },
  ]);
  const fulfilmentStatuses = ref<Option[]>([
    { name: "Sold Out", value: FulfilmentStatus.SOLD_OUT },
    { name: "Bill Sent", value: FulfilmentStatus.BILL_SENT },
    { name: "Bill Viewed", value: FulfilmentStatus.BILL_VIEWED },
    { name: "Zero Priced", value: FulfilmentStatus.HAS_ZERO_PRICED_PRODUCTS },
  ]);

  // Creating Order
  const isShowModalCreate = ref<boolean>(false);
  const creatingOrder = ref<PurchaseOrderManage>({} as PurchaseOrderManage);
  const creatingOrderSupplier = ref<Customer | CustomerSingle>(
    {} as Customer | CustomerSingle
  );

  // Editing Order
  const editingOrder = ref<PurchaseOrderManage>({} as PurchaseOrderManage);
  const editingOrderSupplier = ref<Customer | CustomerSingle>(
    {} as Customer | CustomerSingle
  );

  // Kanban
  const kanbanLanes = ref<KanbanLane[]>([]);
  const editingKanbanLane = ref<KanbanLane>({} as KanbanLane);
  const creatingKanbanLane = ref<KanbanLane>({} as KanbanLane);
  const kanbanComponentKey = ref<number>(0);
  const isEditModeKanban = ref<boolean>(false);

  // Date types and filters
  const dateTypes = ref<Option[]>([
    { name: "Creation Date", value: BillDateTypes.CREATION },
    { name: "Target Date", value: BillDateTypes.TARGET },
  ]);

  const selectedPaymentStatuses = ref<Option[]>([]);
  const targetDateRange = ref<string[]>([]);
  const filters = ref<Filters>({
    type: {
      type: FilterType.DEFAULT,
      title: "Order Type",
      options: orderTypeOptions.value,
      chosenOptions: [],
    },
    status: {
      type: FilterType.TAG_LIST,
      title: "Status",
      options: statusTags.value,
      chosenOptions: [],
    },
    fulfilmentStatus: {
      type: FilterType.DEFAULT,
      title: "Fulfilment Status",
      options: fulfilmentStatuses.value,
      chosenOptions: [],
    },
    pricingMethod: {
      type: FilterType.DEFAULT,
      title: "Pricing Method",
      options: purchasingMethods.value,
      chosenOptions: [],
    },
    sync_statuses: {
      type: FilterType.TAG_LIST,
      title: "Sync Status",
      options: [
        { name: "Pending", value: SyncStatus.PENDING },
        { name: "Synced", value: SyncStatus.SYNCED },
        { name: "Error", value: SyncStatus.ERROR },
      ],
      chosenOptions: [],
    },
    letter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    date: {
      type: FilterType.MAIN_DATE,
      title: "Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    target_date: {
      type: FilterType.DATE_RANGE,
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  const isOpenSupplierProductsModal = ref<boolean>(false);

  const mergeTagsList = ref([
    { name: "User Email", value: NotificationTag.USER_EMAIL },
    { name: "User Name", value: NotificationTag.USER_NAME },
    { name: "Default Store", value: NotificationTag.DEFAULT_STORE },
    { name: "Location", value: NotificationTag.LOCATION },
    { name: "Supplier", value: NotificationTag.CUSTOMER },
    { name: "Order Status", value: NotificationTag.ORDER_STATUS },
    { name: "Order Type", value: NotificationTag.ORDER_TYPE },
    { name: "Order Number", value: NotificationTag.ORDER_NUMBER },
    { name: "Order Details", value: NotificationTag.ORDER_DETAILS },
    { name: "Login Link", value: NotificationTag.LOGIN_LINK },
    { name: "Shipping Address", value: NotificationTag.SHIPPING_ADDRESS },
    { name: "Billing Address", value: NotificationTag.BILLING_ADDRESS },
    { name: "Due Date", value: NotificationTag.DUE_DATE },
    { name: "Payment Link", value: NotificationTag.PAYMENT_LINK },
    { name: "Bill Link", value: NotificationTag.BILL_LINK },
    { name: "Receipt Link", value: NotificationTag.RECEIPT_LINK },
    { name: "Received Date", value: NotificationTag.RECEIVED_DATE },
    // { name: "Onboard Link", value: NotificationTag.ONBOARD_LINK },
  ]);

  // Table
  const pageTable = ref<number>(1);
  const perPageTable = ref<number>(10);

  const selectedRowsIds = ref<any[]>([]);

  // Purchase Orders Functions
  async function getPurchaseOrders(
    params?: any
  ): Promise<ResponseType<PurchaseOrder[]>> {
    try {
      const response = await useVaniloApi("/purchase-orders", {
        params,
      });

      return response as ResponseType<PurchaseOrder[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleOrder(
    id: number | string,
    sort?: SortType
  ): Promise<ResponseType<PurchaseOrder>> {
    try {
      const response = (await useVaniloApi(`/purchase-orders/${id}`, {
        params: {
          ...(sort && { sort }),
        },
      })) as ResponseType<PurchaseOrder>;

      viewingOrder.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function editOrder(
    id,
    order?: PurchaseOrderManage
  ): Promise<ResponseType<PurchaseOrder>> {
    const { shipping_address, billing_address, ...other } =
      order || editingOrder.value;

    try {
      const response = await useVaniloApi(`/purchase-orders/${id}`, {
        method: "POST",
        body: {
          ...other,
          ...(other.items?.length && {
            items: other.items,
          }),
        },
      });

      return response as ResponseType<PurchaseOrder>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function editOrderMerge(payload: {
    id: string | number;
    store_id?: string | number;
    order?: any;
    items?: any[];
    merge?: {
      subtotal?: number;
      tax?: number;
      delete_items?: number[];
    };
  }): Promise<ResponseType<PurchaseOrder>> {
    try {
      const response = await useVaniloApi(`/purchase-orders/${payload?.id}`, {
        method: "POST",
        body: {
          store_id: payload.store_id,
          ...payload.order,
          ...(payload.merge && {
            merge: payload.merge,
          }),
          items: payload.items,
        },
      });

      return response as ResponseType<PurchaseOrder>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function deleteOrder(id: number | string) {
    try {
      const response = (await useVaniloApi(`/purchase-orders/${id}`, {
        method: "DELETE",
      })) as ResponseType<PurchaseOrder>;

      orders.value = orders.value.filter((order) => order.id !== +id);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function calculateTotals(body: PurchaseCalculatePayload) {
    try {
      useIsLoading(true);
      const res = await useVaniloApi("/purchase-orders/calculate", {
        method: "POST",
        body,
      });

      return res as ResponseType<any>;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createOrder(
    order: PurchaseOrderManage
  ): Promise<ResponseType<PurchaseOrder>> {
    const notesStore = useNotesStore();

    const {
      shipping_address,
      shipping_address_id,
      billing_address_id,
      billing_address,
      ...other
    } = order || creatingOrder.value;

    const body = {
      ...other,
      ...(notesStore.notes?.length && {
        notes: notesStore.notes.map((i) => ({
          is_pinned: i.is_pinned,
          is_private: i.is_private,
          text: i.text,
        })),
      }),
    };

    try {
      const response = await useVaniloApi(`/purchase-orders`, {
        method: "POST",
        body,
      });

      return response as ResponseType<PurchaseOrder>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function updateStatus(
    orderId: number | string,
    status?: PurchaseOrderStatus,
    lane_id?: number
  ): Promise<ResponseType<PurchaseOrder>> {
    try {
      const response = await useVaniloApi(
        `/purchase-orders/${orderId}/status`,
        {
          method: "POST",
          body: {
            ...(status && { status }),
            ...(lane_id && { lane_id }),
          },
        }
      );

      return response as ResponseType<PurchaseOrder>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function importOrders(body): Promise<ResponseType<any>> {
    try {
      const response = await useVaniloApi(`/purchase-orders/import`, {
        method: "POST",
        body,
      });

      return response as ResponseType<any>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Order History Functions
  async function getOrderHistory(orderId?: number, params?: any) {
    try {
      const response = await useVaniloApi(
        `/purchase-orders/${orderId || viewingOrder.value.id}/notes`,
        {
          params,
        }
      );

      return response as ResponseType<any>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Kanban Functions
  async function getKanbanLanes(): Promise<ResponseType<KanbanLane[]>> {
    try {
      const response = (await useVaniloApi(
        "/purchase-kanban-lanes"
      )) as ResponseType<KanbanLane[]>;

      kanbanLanes.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateKanbanLanesBulk(lanes: KanbanLane[]) {
    lanes.forEach((lane) => {
      delete lane.position;
      delete lane.type;
      delete lane.meta;
    });

    const body = {
      lanes: lanes.map((lane, index) => {
        if (index === 0 || index === lanes.length - 1) {
          return {
            id: lane.id,
            name: lane.name,
          };
        } else {
          return lane;
        }
      }),
    };

    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        "/purchase-kanban-lanes/bulk-update",
        {
          method: "POST",
          body,
        }
      )) as ResponseType<KanbanLane[]>;

      kanbanLanes.value = response?.data;
      kanbanComponentKey.value++;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createKanbanLane() {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi("/purchase-kanban-lanes", {
        method: "POST",
        body: creatingKanbanLane.value,
      })) as ResponseType<KanbanLane>;

      kanbanLanes.value.splice(kanbanLanes.value.length - 1, 0, response?.data);

      kanbanComponentKey.value += 1;

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateKanbanLane(lane?: KanbanLane) {
    const body = lane
      ? {
          ...lane,
          id: undefined,
        }
      : {
          ...editingKanbanLane.value,
          id: undefined,
        };

    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/purchase-kanban-lanes/${lane?.id || editingKanbanLane.value.id}`,
        {
          method: "POST",
          body,
        }
      )) as ResponseType<KanbanLane>;

      kanbanLanes.value = kanbanLanes.value.map((lane) =>
        lane.id === response?.data.id ? response?.data : lane
      );

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function deleteKanbanLane() {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(
        `/purchase-kanban-lanes/${editingKanbanLane.value.id}`,
        {
          method: "DELETE",
        }
      );

      kanbanLanes.value = kanbanLanes.value.filter(
        (lane) => lane.id !== editingKanbanLane.value.id
      );

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Bulk Actions Functions
  async function purchaseOrdersPrint(id, payload?) {
    try {
      const response = (await useVaniloApi(`/purchase-orders/${id}/print`, {
        method: "POST",
        body: {
          ...payload,
        },
      })) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkPurchaseOrdersUpdate(purchase_orders, payload) {
    try {
      const response = (await useVaniloApi(`/purchase-orders/bulk-update`, {
        method: "POST",
        body: {
          ...(purchase_orders && { purchase_orders }),
          ...payload,
        },
      })) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkPurchaseOrdersSendNotification(payload?, orders?) {
    try {
      const response = (await useVaniloApi(`/purchase-orders/bulk-notify`, {
        method: "POST",
        body: {
          ...(selectedRowsIds.value.length && {
            purchase_orders: selectedRowsIds.value,
          }),
          ...(orders?.length && { purchase_orders: orders }),
          notification: payload,
        },
      })) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkPurchaseOrdersPrintLabel(id, payload?) {
    try {
      const response = (await useVaniloApi(
        `/purchase-orders/${id}/print-label`,
        {
          method: "POST",
          body: {
            ...payload,
          },
        }
      )) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkPurchaseOrdersDelete(orderIds?) {
    try {
      const response = (await useVaniloApi(`/purchase-orders/bulk-delete`, {
        method: "POST",
        body: {
          ...(orderIds && { purchase_orders: orderIds }),
        },
      })) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Purchase Bill Function
  async function getPurchaseBill(id, token) {
    try {
      const response = await useVaniloApi(
        `/purchase-orders/${id}/pay-online?token=${encodeURIComponent(token)}`
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function processPayment({
    orderId,
    paymentMethod,
    amount,
    date,
  }: {
    orderId: number;
    paymentMethod: PaymentMethodGateway;
    amount?: number | string;
    date?: string;
  }) {
    const pm = await useGetPaymentMethod(paymentMethod);

    try {
      const response = await useVaniloApi(`/purchase-orders/${orderId}/pay`, {
        method: "POST",
        body: {
          payment_method_id: pm.id,
          ...(amount && { amount }),
          ...(date && { date }),
        },
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function undoPayments(orders: { id: number; amount: number }[]) {
    try {
      const response = await useVaniloApi("/purchase-orders/undo-payments", {
        method: "POST",
        body: {
          orders,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getPaymentHistory(orderId: number, params?: any) {
    try {
      const response = await useVaniloApi(
        `/purchase-orders/${orderId}/payments-history`,
        {
          params,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    orders,
    meta,
    viewingOrder,
    cachedOrder,
    editMode,
    statusTags,
    consignmentOrderStatuses,
    pricedOrderStatuses,
    orderTypeOptions,
    purchasingMethods,
    isShowModalCreate,
    creatingOrder,
    creatingOrderSupplier,
    editingOrder,
    editingOrderSupplier,
    kanbanLanes,
    editingKanbanLane,
    creatingKanbanLane,
    kanbanComponentKey,
    isEditModeKanban,
    selectedPaymentStatuses,
    targetDateRange,
    filters,
    isOpenSupplierProductsModal,
    pageTable,
    perPageTable,
    selectedRowsIds,
    dateTypes,
    mergeTagsList,
    getPurchaseOrders,
    getSingleOrder,
    editOrder,
    editOrderMerge,
    deleteOrder,
    calculateTotals,
    createOrder,
    updateStatus,
    importOrders,
    getOrderHistory,
    getKanbanLanes,
    updateKanbanLanesBulk,
    createKanbanLane,
    updateKanbanLane,
    deleteKanbanLane,
    purchaseOrdersPrint,
    bulkPurchaseOrdersUpdate,
    bulkPurchaseOrdersSendNotification,
    bulkPurchaseOrdersPrintLabel,
    bulkPurchaseOrdersDelete,
    getPurchaseBill,
    processPayment,
    undoPayments,
    getPaymentHistory,
  };
});
